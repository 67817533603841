<template>
  <div>
    <!-- 検索結果一覧 -->
    <div>
      <b-icon icon="info-circle-fill" variant="caccent"></b-icon> 一斉連絡 :
      チェックを付けた連絡先に一斉連絡します。全チェックを選択すると全てのページが対象になります。
    </div>
    <div class="mb-3">
      <b-icon icon="info-circle-fill" variant="csub"></b-icon> CSV出力 :
      一覧全てを対象にcsvが作成されます。作成後、表示されるリンクからダウンロードしてください。
    </div>
    <b-alert v-if="csvPath" show variant="csub">
      <a v-bind:href="csvPath" class="alert-link"
        >CSVの作成が完了しました {{ csvPath }}</a
      >
    </b-alert>
    <b-form-row>
      <b-col cols="6" sm="4" lg="2">
        <b-button
          v-b-modal="'modal'"
          variant="caccent"
          class="w-100 mb-3"
          v-on:click="openMessageMultiple()"
        >
          <b-icon icon="people-fill"></b-icon>
          一斉連絡
        </b-button>
        <div v-if="isModalActiveMultiple">
          <message-modal
            v-bind:destination="destinationIdList"
            v-bind:display-name="destinationNameList"
            v-bind:body-header="bodyHeader"
            v-bind:target="target"
            v-on:close-modal="closeMessageMultiple($event)"
          />
        </div>
      </b-col>
      <b-col cols="6" sm="4" lg="2">
        <b-button variant="csub" class="w-100 mb-3" v-on:click="getCsv()">
          <b-icon icon="file-earmark-text"></b-icon>
          CSV出力
        </b-button>
      </b-col>
      <div class="w-100 d-sm-block d-lg-none"></div>
      <b-col sm="4" offset-lg="2" lg="2" class="mb-3">
        <b-input-group append="件/1ページ">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            v-bind:options="pageOptions"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col sm="8" lg="4">
        <b-pagination
          v-model="currentPage"
          v-bind:total-rows="totalRows"
          v-bind:per-page="perPage"
          align="fill"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <div class="text-right text-cmain">
      {{ (currentPage - 1) * perPage + 1 }}-
      <span v-if="currentPage * perPage < items.length">
        {{ currentPage * perPage }}
      </span>
      <span v-else>{{ items.length }}</span>
      （{{ items.length }}件中）
    </div>
    <b-table
      v-bind:items="items"
      v-bind:fields="fields"
      v-bind:current-page="currentPage"
      v-bind:per-page="perPage"
      v-bind:sort-by.sync="sortBy"
      v-bind:sort-desc.sync="sortDesc"
      responsive
      hover
      bordered
      sticky-header="500px"
      head-variant="dark"
      select-mode="single"
      selectable
      v-on:row-selected="onRowSelected"
    >
      <template v-slot:head(check)>
        <b-form-checkbox
          v-model="allChecked"
          v-on:change="checkAll()"
          class="box-large"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(check)="row">
        <b-form-checkbox
          v-bind:value="row.item.id"
          v-model="checked"
          class="box-large"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(button)="row">
        <b-button
          v-b-modal="'modal'"
          variant="caccent"
          size="sm"
          v-on:click.stop="openMessage(row.index, row.item)"
        >
          連絡
        </b-button>
        <div v-if="isModalActive[row.index]">
          <message-modal
            v-bind:destination="destinationIdList"
            v-bind:display-name="destinationNameList"
            v-bind:body-header="bodyHeader"
            v-bind:target="target"
            v-on:close-modal="closeMessage(row.index, $event)"
          />
        </div>
      </template>
    </b-table>
    <p v-if="message" class="text-center text-caccent">
      {{ message }}
    </p>
    <p v-else-if="items.length == 0" class="text-center text-csub">
      検索した結果が表示されます
    </p>
  </div>
</template>

<script>
import MessageModal from '@/components/common/MessageModal'
export default {
  props: {
    items: { default: () => [] },
    fields: { default: () => [] },
    message: { default: null },
    target: { default: null },
    defaultSort: { default: () => ({ by: null, desc: true }) },
  },
  components: {
    MessageModal,
  },
  data() {
    return {
      isModalActive: [],
      isModalActiveMultiple: false,
      destinationIdList: [], //message
      destinationNameList: [], //message
      bodyHeader: null, //message
      checked: [], //checked id list
      allChecked: false,
      csvPath: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 30, 50, 100],
      totalRows: 1,
      sortBy: this.defaultSort.by,
      sortDesc: this.defaultSort.desc,
    }
  },
  watch: {
    items(val) {
      console.log('** watch items **', val)
      const array = new Array(this.perPage).fill(false)
      this.isModalActive = array
      this.checked = []
      this.allChecked = false
      this.csvPath = null
      this.totalRows = this.items.length
    },
    checked(val) {
      console.log('** watch checked **', val)
      if (val.length == 0) {
        this.allChecked = false
      } else if (val.length == this.items.length) {
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    },
    perPage(val) {
      console.log('** watch perPage **', val)
      const array = new Array(val).fill(false)
      this.isModalActive = array
    },
  },
  methods: {
    checkAll() {
      // すべて選択CheckBox
      if (this.allChecked) {
        this.checked = this.items.map((item) => item.id)
      } else {
        this.checked = []
      }
    },
    openMessage(index, item) {
      // message送信画面を開く
      console.log('** open modal **', index, item)
      if (!item.email) {
        alert('メールアドレスの設定がないため連絡できません。')
        return
      }
      this.destinationIdList.push(item.id)
      if (this.target == 3) {
        this.destinationNameList.push(item.surname + item.firstname)
        this.bodyHeader = item.surname + ' ' + item.firstname + ' 様'
      } else {
        this.destinationNameList.push(item.name)
        let recipient = null
        if (this.target == 1) {
          recipient = item.dataHandler
        } else if (this.target == 2) {
          recipient = item.contact
        }
        if (recipient) {
          this.bodyHeader = recipient + ' 様'
        } else {
          this.bodyHeader = '担当者殿'
        }
      }
      this.isModalActive.splice(index, 1, true)
    },
    openMessageMultiple() {
      // message送信画面を開く一括送信
      console.log('** open modal multiple **')
      if (this.checked.length == 0) {
        alert('連絡先が選択されていません。')
        return
      }
      const checkedRow = this.items.filter(
        (item) => this.checked.indexOf(item.id) !== -1
      )
      if (checkedRow.some((r) => !r.email)) {
        alert('メールアドレスの設定がない連絡先が含まれています。')
        return
      }
      this.destinationIdList = this.checked
      if (this.target == 3) {
        this.destinationNameList = checkedRow.map(
          (item) => item.surname + item.firstname
        )
        this.bodyHeader = '%surname% %firstname% 様'
      } else {
        this.destinationNameList = checkedRow.map((item) => item.name)
        this.bodyHeader = '担当者殿'
      }
      this.isModalActiveMultiple = true
    },
    closeMessage(index, event) {
      // message送信画面を閉じたとき
      console.log('** close modal **', index, event)
      this.destinationIdList = []
      this.destinationNameList = []
      this.isModalActive.splice(index, 1, false)
    },
    closeMessageMultiple(event) {
      // message送信画面を閉じたとき一括送信
      console.log('** close modal multiple**', event)
      this.destinationIdList = []
      this.destinationNameList = []
      this.isModalActiveMultiple = false
    },
    onRowSelected(item) {
      console.log('** row selected **', item[0])
      this.$emit('row-selected', item[0])
    },
    getCsv() {
      // csv出力
      console.log('** get csv **')
      if (this.items.length == 0) {
        alert('検索された一覧がありません。')
        return
      }
      this.$axiosToken
        .post('/api/csv', {
          target: this.target,
          idList: this.items.map((item) => item.id),
        })
        .then((response) => {
          console.log(response)
          this.csvPath = response.data.uri
        })
        .catch((error) => {
          console.log(error)
          alert('CSVの出力に失敗しました。')
        })
    },
  },
}
</script>
