<template>
  <!--  Message送信ボタン共通 -->
  <div>
    <b-button
      v-b-modal="'modal'"
      variant="caccent"
      class="w-100"
      v-on:click="openMessage()"
    >
      <b-icon icon="person-fill"></b-icon>
      <span v-if="target == 3">開設者へ連絡</span>
      <span v-else>担当者へ連絡</span>
    </b-button>
    <div v-if="isModalActive">
      <message-modal
        v-bind:destination="destinationIdList"
        v-bind:display-name="destinationNameList"
        v-bind:body-header="bodyHeader"
        v-bind:target="target"
        v-on:close-modal="closeMessage($event)"
      />
    </div>
  </div>
</template>

<script>
import MessageModal from '@/components/common/MessageModal'
export default {
  props: ['target', 'targetInfo'],
  components: {
    MessageModal,
  },
  data() {
    return {
      isModalActive: false,
      destinationIdList: [], //message
      destinationNameList: [], //message
      bodyHeader: null, //message
    }
  },
  methods: {
    openMessage() {
      // message送信画面を開く
      console.log('** open modal **')
      if (!this.targetInfo.email) {
        alert('メールアドレスの設定がないため連絡できません。')
        return
      }
      this.destinationIdList.push(this.targetInfo.id)

      if (this.target == 3) {
        this.destinationNameList.push(
          this.targetInfo.surname + this.targetInfo.firstname
        )
        this.bodyHeader =
          this.targetInfo.surname + ' ' + this.targetInfo.firstname + ' 様'
      } else {
        this.destinationNameList.push(this.targetInfo.name)
        let recipient = null
        if (this.target == 1) {
          recipient = this.targetInfo.dataHandler
        } else if (this.target == 2) {
          recipient = this.targetInfo.contact
        }
        if (recipient) {
          this.bodyHeader = recipient + ' 様'
        } else {
          this.bodyHeader = '担当者殿'
        }
      }
      this.isModalActive = true
    },
    closeMessage(event) {
      // message送信画面を閉じたとき
      console.log('** close modal **', event)
      this.destinationIdList = []
      this.destinationNameList = []
      this.isModalActive = false
      if (event) {
        // message送信していれば親へ通知
        this.$emit('message-sent', event)
      }
    },
  },
}
</script>
