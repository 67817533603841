<template>
  <!-- メッセージ履歴Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3">メッセージ履歴</b-card-title>
      <base-table
        v-bind:fields="fields"
        v-bind:items="messages"
        v-bind:default-sort="defaultSort"
        v-bind:is-filter="true"
      />
      <p v-if="messages.length == 0" class="text-center text-caccent">
        メッセージ履歴がありません。
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseTable from '@/components/common/BaseTable'
export default {
  components: {
    BaseTable,
  },
  props: ['messages'],
  data() {
    return {
      fields: [
        { key: 'date', label: '日時', sortable: true },
        { key: 'title', label: '件名' },
      ],
      defaultSort: { by: 'date', desc: true },
    }
  },
}
</script>
